import { PleaseUpgradeTableItem } from 'components/pleaseUpgradeTableItem';
import { Alignment, ColumnDefinition, ColumnType } from 'components/table/types';
import React from 'react';
import { Portfolio } from 'redux/reducers/portfoliosReducer';
import { ActionButtons } from 'views/Portfolios/Overview/actionButtons';
import { RowCheckbox } from 'views/Portfolios/Overview/rowCheckbox';

const columns: Array<ColumnDefinition<Portfolio>> = [
  {
    name: 'portfolio_id',
    label: '',
    type: ColumnType.custom,
    render: (value: Portfolio['portfolio_id']): React.ReactNode => {
      if (value) {
        return <RowCheckbox portfolioId={value} />;
      }
    },
    headerAlignment: Alignment.center,
    width: 60,
  },
  {
    name: 'name',
    label: 'Name',
    type: ColumnType.text,
    sortable: true,
    alignment: Alignment.left,
  },
  {
    name: 'access_mode',
    label: 'Access',
    type: ColumnType.custom,
    headerAlignment: Alignment.center,
    render: (value: Portfolio[keyof Portfolio] | null): React.ReactNode => {
      const text = ((): string => {
        switch (value) {
          case 'read-only':
            return 'Read Only';
          case 'read-write':
            return 'Read & Write';
          case 'owner':
            return 'Owner';
          default:
            return 'Unknown';
        }
      })();

      return <span className="block text-green-light italic text-center w-full">{text}</span>;
    },
  },
  {
    name: 'count',
    label: 'Count',
    type: ColumnType.numeric,
    precision: 0,
    sortable: true,
    alignment: Alignment.center,
  },
  {
    name: 'summary',
    label: 'Summary',
    type: ColumnType.text,
    weight: 3,
    alignment: Alignment.left,
  },
  {
    name: 'created_at',
    label: 'Created Date',
    type: ColumnType.date,
    weight: 1,
    alignment: Alignment.center,
  },
  {
    name: 'portfolio_type',
    label: '',
    type: ColumnType.custom,
    render: (_: Portfolio['portfolio_type'], portfolio: Portfolio): React.ReactNode => {
      if (!portfolio.portfolio_id) {
        return (
          <div>
            <PleaseUpgradeTableItem anchorToNext="tr" />
          </div>
        );
      }
      return <ActionButtons portfolio={portfolio} />;
    },
    headerAlignment: Alignment.center,
    width: 153,
  },
];

export default columns;
