import React, { CSSProperties, useMemo } from 'react';

export type DynamicColor = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

interface OwnProps {
  readonly size: 'small' | 'normal';
  readonly color: 'blue' | 'green' | DynamicColor;

  readonly disableHover?: boolean;

  onClick?(): void;
}

type Props = React.PropsWithoutRef<React.PropsWithChildren<OwnProps>> &
  React.RefAttributes<HTMLDivElement>;

export const MarkerDot: React.FC<Props> = React.forwardRef(function MarkerDot(
  { children, size, color, disableHover = false, onClick }: Props,
  ref: React.Ref<HTMLDivElement>,
): React.ReactElement {
  const computedColor = useMemo((): string => {
    switch (color) {
      case 'blue':
        return '#197ACF'; // Tailwind's primary blue color (hex value)
      case 'green':
        return '#047857'; // green-700 hex
      default:
        return (
          {
            0: '#9e0142',
            1: '#d53e4f',
            2: '#f46d43',
            3: '#fdae61',
            4: '#fee08b',
            5: '#e6f598',
            6: '#abdda4',
            7: '#66c2a5',
            8: '#3288bd',
            9: '#5e4fa2',
          }[color] ?? '#9e0142'
        );
    }
  }, [color]);

  const computedSize = useMemo((): number => {
    switch (size) {
      case 'small':
        return 4;
      case 'normal':
        return 5;
    }
  }, [size]);

  const outerClassName = useMemo((): string => {
    const classes = [
      'relative',
      `w-${computedSize - 2}`,
      `h-${computedSize - 2}`,
      'm-1',
      'rounded-full',
      ...(disableHover ? [] : ['hover:transform', 'hover:scale-150', 'transition-all']),
      'z-1',
    ];

    return classes.join(' ');
  }, [computedSize, disableHover]);

  const innerClassName = useMemo((): string => {
    const classes = [
      'absolute',
      `w-${computedSize}`,
      `h-${computedSize}`,
      'rounded-full',
      'bg-inherit',
      'opacity-30',
      '-top-1',
      '-left-1',
      'z-0',
    ];

    return classes.join(' ');
  }, [computedSize]);

  const outerStyle = useMemo(
    (): CSSProperties => ({
      backgroundColor: computedColor,
    }),
    [computedColor],
  );

  return (
    <div ref={ref} className={outerClassName} style={outerStyle} onClick={onClick}>
      <div className={innerClassName} />
      <>{children}</>
    </div>
  );
});
