import { Placement } from '@popperjs/core';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { defaultOptions } from 'utils/popper';

interface Props {
  readonly isOpen: boolean;
  readonly placement: Placement;
  readonly target: HTMLDivElement | null;
  readonly variant: 'dark' | 'light' | 'error';
  readonly fitContent?: boolean;
}

export const Popup: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  target,
  placement,
  variant,
  children,
  fitContent = false,
}: React.PropsWithChildren<Props>): React.ReactElement | null => {
  const [tooltip, setTooltip] = useState<HTMLDivElement | null>(null);
  const [arrow, setArrow] = useState<HTMLDivElement | null>(null);

  const popperOptions = useMemo(
    (): any =>
      defaultOptions(document.body, {
        offset: [0, 10],
        arrow: arrow,
        placement: placement,
        preventOverflow: true,
      }),
    [arrow, placement],
  );
  const { styles, attributes, update } = usePopper(target, tooltip, popperOptions);

  const colorsClass = useMemo((): string => {
    switch (variant) {
      case 'dark':
        return 'bg-tooltip text-white mt-px';
      case 'light':
        return 'bg-white border border-blue text-blue';
      case 'error':
        return 'bg-red-500 text-white mt-px';
    }
  }, [variant]);

  const arrowColorsClass = useMemo((): string => {
    return [colorsClass, 'border-b-0 border-r-0 -mt-px'].join(' ');
  }, [colorsClass]);

  const arrowClass = useMemo((): string => {
    if (placement === 'right') {
      return [
        arrowColorsClass,
        'absolute -left-4 w-2 h-2 z-10 transform -rotate-45',
        variant === 'light' ? '-ml-px' : undefined,
      ].join(' ');
    }

    return [arrowColorsClass, 'absolute -top-2 w-2 h-2 transform rotate-45'].join(' ');
  }, [arrowColorsClass, placement, variant]);

  const containerClass = useMemo(
    (): string =>
      [
        colorsClass,
        'inline-block py-1 px-3 rounded-md pointer-events-none z-1 shadow-md',
        fitContent ? undefined : 'max-w-tooltip',
      ].join(' '),
    [colorsClass, fitContent],
  );

  useEffect((): void => {
    update?.();
  }, [update]);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div ref={setTooltip} className={containerClass} style={styles.popper} {...attributes.popper}>
      <div ref={setArrow} className="w-2 h-2" style={styles.arrow}>
        <div className={arrowClass} />
      </div>
      {children}
    </div>,
    document.body,
  );
};
