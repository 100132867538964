import { Disabler } from 'components/disabler';
import { HeaderButton } from 'components/header/UserHeader/components/headerButton';
import { NotificationsIcon } from 'components/header/UserHeader/components/notificationsIcon';
import SVGIcon from 'components/icons/SVGIcon';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { getUserNotificationsBell, userNotificationsSelector } from 'redux/reducers/sharedReducer';
import { isBeta } from 'utils';

export const NavButtons: React.FC = (): React.ReactElement => {
  const location = useLocation();
  const dispatch = useDispatch<any>();

  const notifications = useSelector(userNotificationsSelector);

  useEffect((): void => {
    // TODO: make cancellable
    dispatch(getUserNotificationsBell());
  }, [dispatch]);

  useEffect((): void => {
    const count = notifications.total_count;
    if (count === 0) {
      document.title = 'Capnote | Discover the Future of Financial Analysis';
    } else {
      document.title = `Capnote | Discover the Future of Financial Analysis (${count})`;
    }
  }, [notifications.total_count]);

  const activeIcon = useMemo(():
    | 'none'
    | 'notifications'
    | 'my-network'
    | 'settings'
    | 'invite' => {
    const { pathname } = location;
    if (pathname.startsWith('/connections')) {
      return 'my-network';
    } else if (pathname.startsWith('/notifications')) {
      return 'notifications';
    } else if (pathname.startsWith('/profile/settings')) {
      return 'settings';
    } else if (pathname.startsWith('/refer-a-friend')) {
      return 'invite';
    }

    return 'none';
  }, [location]);

  return (
    <div className="flex items-center gap-x-4 mx-4">
      <div className="flex items-center lg:gap-x-4 md:gap-x-2">
        <NavLink to="/refer-a-friend">
          <HeaderButton title="Refer & Earn" icon="invite-icon" active={activeIcon === 'invite'} />
        </NavLink>
        <NavLink to="/connections">
          <HeaderButton
            title="My Network"
            icon="teammates-icon"
            active={activeIcon === 'my-network'}
          />
        </NavLink>
        {isBeta ? null : (
          <Disabler disabled={true}>
            <HeaderButton title="Direct Message" icon="direct-message-icon" />
          </Disabler>
        )}
        <NotificationsIcon active={activeIcon === 'notifications'} />
        <NavLink to="/profile/settings">
          <HeaderButton
            title="Settings"
            icon={<SVGIcon name="settings" className="fill-current w-6.5 h-6.5" />}
            active={activeIcon === 'settings'}
          />
        </NavLink>
      </div>
    </div>
  );
};
