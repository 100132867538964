import { CapnoteMap } from 'components/capnoteMap';
import { DynamicColor, MarkerDot } from 'components/capnoteMap/components/markerDot';
import DropdownFilter, { DropdownItem } from 'components/dropdownFilter/DropdownFilter';
import { noop } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PortfoliosCompaniesMapItem,
  PortfoliosMapLocation,
  PortfoliosRevenuesMapItem,
  portfoliosSelector,
} from 'redux/reducers/portfoliosReducer';
import { getPortfoliosCompaniesMapAction } from 'redux/services/portfolioService';
// import { getPortfoliosRevenuesMapAction } from 'redux/services/portfolioService';
import { OperationType } from 'types/operationType';
import { Portfolio } from 'types/organization/types';
import { DataDisclaimer } from 'views/DataAnalysis/Company/dataDisclaimer';
import { reducers } from 'views/Portfolios/Maps/helpers';
import { LegendItem } from 'views/Portfolios/Maps/legendItem';

const PortfoliosMaps: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch();
  const { revenuesMap: revenues, companiesMap: companies } = useSelector(portfoliosSelector);
  const [currentFilter, setCurrentFilter] = useState<string>(OperationType.subsidiaries);
  const [active, setActive] = useState<string | null>(null);

  useEffect((): VoidFunction | void => {
    return dispatch(getPortfoliosCompaniesMapAction());
  }, [dispatch]);

  const markers = useMemo((): ReadonlyArray<
    | PortfoliosMapLocation<PortfoliosCompaniesMapItem>
    | PortfoliosMapLocation<PortfoliosRevenuesMapItem>
  > => {
    switch (currentFilter) {
      case OperationType.subsidiaries:
        return companies;
      case OperationType.revenues:
        return revenues;
      default:
        return [];
    }
  }, [companies, currentFilter, revenues]);

  const colors = useMemo((): Record<string, DynamicColor> => {
    return markers.reduce(reducers.colors, {});
  }, [markers]);

  const portfolios = useMemo((): readonly Portfolio[] => {
    return markers
      .reduce(reducers.portfolios, [])
      .slice()
      .sort(({ name: name1 }: Portfolio, { name: name2 }: Portfolio): number =>
        name1.localeCompare(name2),
      );
  }, [markers]);

  return (
    <div className="flex flex-col flex-grow">
      <div className="flex items-center justify-end my-4">
        <DropdownFilter selectedId={currentFilter} options={options} onSelect={setCurrentFilter} />
      </div>
      <div className="flex-grow">
        <CapnoteMap markers={markers} colors={colors} active={active} onMarkerClick={noop} />
        <div className="flex items-center gap-8 flex-wrap mt-2">
          {portfolios.map((portfolio: Portfolio): React.ReactNode => {
            return (
              <LegendItem key={portfolio.id} data={portfolio.id} onHover={setActive}>
                <div className="flex items-center gap-1 cursor-pointer">
                  <MarkerDot size="normal" color={colors[portfolio.id]} disableHover={true} />
                  <p className="text-gray">
                    <span>{portfolio.name}</span>
                  </p>
                </div>
              </LegendItem>
            );
          })}
        </div>
      </div>
      <DataDisclaimer />
    </div>
  );
};

export default PortfoliosMaps;

const options: ReadonlyArray<DropdownItem<OperationType>> = [
  { id: OperationType.subsidiaries, label: 'Show: Companies' },
  // TODO: enable someday
  /* { id: OperationType.revenues, label: 'Show: Revenues' }, */
];
