import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Identifiable } from 'components/table/types';
import { Notification } from 'redux/reducers/notificationsReducer';
import { Coordinate } from 'redux/reducers/portfoliosReducer';
import { Member } from 'sharable/types';
import { AccessMode } from 'types/accessMode';
import { Page } from 'types/page';

export interface Location {
  id: string;
  name: string;
  country: string;
  type: string;
  coordinates: Coordinate | null;
  lo_type: string;
}

export interface MapCompany {
  readonly kind: string;
  readonly id: string;
  readonly name: string;
  readonly country: string;
}

export interface SubsidiariesMapCompany extends MapCompany {
  readonly kind: 'portfolioCompaniesSubsidiaries';
  readonly subsidiaries: string[];
}

export interface RevenuesMapCompany extends MapCompany {
  readonly kind: 'portfolioCompaniesRevenues';
  readonly revenues: number;
  readonly percent_of_total: number;
}

export interface CompaniesLocations<T extends MapCompany> {
  readonly kind: T['kind'];
  readonly id: string;
  readonly coordinates: Coordinate;
  readonly country: string;
  readonly companies: T[];
  readonly region: string;
}

export const isSubsidiariesMapCompany = (
  company: MapCompany | RevenuesMapCompany | SubsidiariesMapCompany,
): company is SubsidiariesMapCompany => {
  return 'subsidiaries' in company;
};

export const isRevenuesMapCompany = (
  company: MapCompany | RevenuesMapCompany | SubsidiariesMapCompany,
): company is RevenuesMapCompany => {
  return 'revenues' in company;
};

export interface Company {
  organization_id: string;
  relationship_id: string;
  currency: string;
  name: string;
  product: string;
  relationship: string;
  size_of_relationship: number;
  locations: Location[];
  fifty_two_week_high?: number;
  fifty_two_week_high_change_percent?: number;
  market_price?: number;
  market_price_percent?: number;
}

export interface MarketPriceAndChange {
  value?: number;
  percentage?: number;
}

export interface PortfolioCompany extends Identifiable {
  relationship_id: string | null;
  organization_id: string | null;
  name: string;
  currency: string | null;
  size_of_relationship: number | null;
  product: string;
  relationship: string;
  market_price: number;
  market_price_percent: number;
  fifty_two_week_high: number;
  fifty_two_week_high_change_percent: number;
  ticker: string | null;
  exchange: string | null;
  daily: MarketPriceAndChange;
  yearly: MarketPriceAndChange;
  added_at: string;
}

export interface Portfolio {
  id: string;
  name: string;
  summary: string;
  access_mode: AccessMode;
  members_count: number;
  tags: string[];
  type: 'Public' | 'Private';
}

export interface PortfolioCompaniesMapEntry {
  readonly kind: 'companiesMapEntry';

  id: string;
  companies: Array<{
    id: string;
    name: string;
  }>;
  country: string;
  coordinates: Coordinate;
}

export interface PortfolioState {
  companiesMap: PortfolioCompaniesMapEntry[];
  portfolio: Portfolio;
  loading: boolean;
  error: string;
  operatingGeographiesFilters: string[];
  companies: PortfolioCompany[];
  portfolioMembers: Member[];
  notifications: Page<Notification>;
  failedAddMessage: string | null;
}

const emptyPortfolio: Portfolio = {
  id: '',
  name: '',
  summary: '',
  access_mode: AccessMode.readOnly,
  members_count: 0,
  tags: [],
  type: 'Private',
};

// Initial State
const initialState: PortfolioState = {
  portfolio: emptyPortfolio,
  portfolioMembers: [],
  companies: [],
  companiesMap: [],
  loading: false,
  error: '',
  operatingGeographiesFilters: [],
  notifications: Page.empty(),
  failedAddMessage: null,
};

const convertValuePercentageFields = (company: PortfolioCompany): PortfolioCompany => ({
  ...company,
  id: company.relationship_id ?? Math.random().toString(),
  daily: {
    value: company.market_price,
    percentage: company.market_price_percent,
  },
  yearly: {
    value: company.fifty_two_week_high,
    percentage: company.fifty_two_week_high_change_percent,
  },
});

const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    // PORTFOLIO DETAILS LAYOUT
    getPortfolioCompanies: state => {
      state.loading = true;
    },
    getPortfolioCompaniesSucceeded: (state, { payload }) => {
      state.companies = payload?.map(convertValuePercentageFields) ?? [];
      state.loading = false;
    },
    getPortfolioCompaniesFailed: state => {
      state.loading = false;
    },
    getPortfolioById: state => {
      // Reset all so that it is clean at the moment of seeing it
      Object.assign(state, initialState);
      state.loading = true;
    },
    getPortfolioByIdSuccess: (state, { payload }) => {
      state.portfolio = { ...payload, organizations: [] };
    },
    getPortfolioCompaniesMap: state => {
      state.companiesMap = [];
    },
    getPortfolioCompaniesMapSuccess: (
      state,
      { payload }: PayloadAction<Array<Omit<PortfolioCompaniesMapEntry, 'kind'>>>,
    ) => {
      state.companiesMap = payload.map(
        (incomplete: Omit<PortfolioCompaniesMapEntry, 'kind'>): PortfolioCompaniesMapEntry => ({
          ...incomplete,
          kind: 'companiesMapEntry',
        }),
      );
    },
    getPortfolioByIdFailed: (state, { payload }) => {
      state.error = payload;
    },
    // Edit portfolio from portfolio details screen
    editPortfolioDetailsSuccess: (state, { payload }) => {
      state.portfolio = { ...state.portfolio, ...payload };
      state.loading = false;
      state.error = '';
    },
    editPortfolioDetailsFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // Add Company to User's Portfolio
    addCompany: state => {
      state.loading = true;
      state.error = '';
    },
    addCompanyFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // Add Company to User's Portfolio
    editCompany: state => {
      state.loading = false;
      state.error = '';
    },
    editCompanySuccess: (state, { payload }) => {
      // TO DO
      state.companies = state.companies.map((company: PortfolioCompany): PortfolioCompany => {
        if (company.organization_id === payload.organization_id) {
          return convertValuePercentageFields(payload);
        } else {
          return company;
        }
      });
      state.loading = false;
      state.error = '';
    },
    editCompanyFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // Clears Error State
    clearErrors: state => {
      state.error = '';
    },
    getOperationGeographiesSuccess: (state, { payload }) => {
      state.operatingGeographiesFilters = payload;
    },
    getPortfolioMembers: state => {
      state.portfolioMembers = [];
    },
    getPortfolioMembersSuccess: (state, { payload }) => {
      state.portfolioMembers = payload;
    },
    getPortfolioMembersFailed: (state, { payload }) => {
      state.error = payload;
    },
    getPortfolioNotifications: (state: PortfolioState): void => {
      state.loading = true;
      state.notifications = Page.empty();
    },
    getPortfolioNotificationsFailed: (state: PortfolioState): void => {
      state.loading = false;
    },
    getPortfolioNotificationsSuccess: (
      state: PortfolioState,
      { payload }: PayloadAction<Page<Notification>>,
    ): void => {
      state.loading = false;
      state.notifications = payload;
    },
    getPortfoliosCompaniesServiceFailedCompanies: (
      state: PortfolioState,
      { payload }: PayloadAction<string>,
    ): void => {
      state.failedAddMessage = payload;
    },
    resetFailedAddMessage: (state: PortfolioState): void => {
      state.failedAddMessage = null;
    },
  },
});

export const portfolioCompaniesSelector = (state: { portfolioReducer: PortfolioState }) =>
  state.portfolioReducer.companies;
export const portfolioSelector = (state: { portfolioReducer: PortfolioState }) =>
  state.portfolioReducer.portfolio;
export const portfolioMembersSelector = (state: { portfolioReducer: PortfolioState }) =>
  state.portfolioReducer.portfolioMembers;
export const loadingPortfolioSelector = (state: { portfolioReducer: PortfolioState }) =>
  state.portfolioReducer.loading;
export const portfolioStateSelector = (state: {
  portfolioReducer: PortfolioState;
}): PortfolioState => state.portfolioReducer;
export const portfoliosCompaniesMapSelector = (state: { portfolioReducer: PortfolioState }) =>
  state.portfolioReducer.companiesMap;
export const failedAddMessageSelector = (state: {
  portfolioReducer: PortfolioState;
}): string | null => state.portfolioReducer.failedAddMessage;
export const portfolioNotificationsSelector = (state: {
  portfolioReducer: PortfolioState;
}): Page<Notification> => state.portfolioReducer.notifications;
export const portfolioLoadingSelector = (state: { portfolioReducer: PortfolioState }): boolean =>
  state.portfolioReducer.loading;

// Actions
export const {
  getPortfolioById,
  getPortfolioByIdSuccess,
  getPortfolioByIdFailed,
  editPortfolioDetailsSuccess,
  editPortfolioDetailsFailed,

  // Company
  getPortfolioCompanies,
  getPortfolioCompaniesSucceeded,
  getPortfolioCompaniesFailed,
  getPortfoliosCompaniesServiceFailedCompanies,
  addCompany,
  addCompanyFailed,
  editCompany,
  editCompanySuccess,
  editCompanyFailed,
  getPortfolioMembers,
  getPortfolioMembersFailed,
  getPortfolioMembersSuccess,
  getPortfolioCompaniesMap,
  getPortfolioCompaniesMapSuccess,
  getPortfolioNotifications,
  getPortfolioNotificationsSuccess,
  getPortfolioNotificationsFailed,

  resetFailedAddMessage,
} = portfolioSlice.actions;

export default portfolioSlice.reducer;
